/**
 * youtube video overlay launcher
 */
(function($) {
  Drupal.behaviors.video_youtube = {
    attach: function(context, settings) {
      var _width = $(window).width() > 808 ? '808px' : '100%';
      var _height = $(window).height() > 609 ? '609px' : '100%';
      var related = $('.video-launcher').data('youtube-related') || 0;
      $('.video-launcher').colorbox({iframe: true, width: _width, height: _height, className: 'colorbox__youtube'});
      $('.video-launcher').each(function(e) {
        var youtube_src = $(this).attr('href');
        var res = youtube_src.split('?');
        if (!res[1]) {
          youtube_src += '?autoplay=1&rel=' + related;
          $(this).attr('href', youtube_src);
        }
      });

      // deeplink to autoplay video
      var isAutoPlay = window.location.hash.substr(1);
      if (isAutoPlay == 'video-autoplay') {
        $('.video-section__container:not(.cancel-autoplay)').each(function() {
          $(this).find('.video-launcher').trigger('click');
        });
      }
    } // attach
  };
})(jQuery);
